@font-face {
    font-family: 'ROKGR';
    font-style: normal;
    font-weight: 400;
    src: url(../font/ROKGR.woff) format('woff'), url(../font/ROKGR.ttf) format('truetype'),
        url(../font/ROKGR.eot) format('embedded-opentype');
    /*unicode-range: U+AC00-D7A3;*/
}

@font-face {
    font-family: 'Jamsil';
    font-style: normal;
    font-weight: bold;
    src: url(../font/Jamsil.ttf) format('truetype');
    unicode-range: U+0030-0039, U+0041-005A, U+0061-007A;
}

* {
    font-family: 'Jamsil', 'ROKGR';
}

span,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
button,
th,
td,
label,
input,
textarea,
.MuiInputBase-input {
    transform: skew(0.04deg);
}
