/*-------------------------
    Variables
-------------------------*/
:root {
    // Color

    --color-header: #ff4d4f;

    // Transition
    --transition: all 0.3s ease-in-out;

    //Fonts Size
    --font-body-1: 18px;
    --font-body-2: 16px;
    --font-body-3: 14px;
    --font-body-4: 12px;
}
