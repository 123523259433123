/*-------------------------
   Header Styles
-------------------------*/

a {
    display: inline-block;
    text-decoration: none;
    transition: var(--transition);
}

th {
    background-color: #f6f6f6;
}

// Main Nav

.mainmenu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 0;
    > li {
        margin: 0 24px;
        > a {
            color: var(--color-black);
            font-weight: 500;
            font-size: var(--font-body-2);
            //font-family: var(--font-primary);
            line-height: 60px;
            height: 60px;
            display: block;
            position: relative;
            transition: var(--transition);
            &::before {
                content: '';
                height: 2px;
                width: 0;
                background-color: var(--color-header);
                position: absolute;
                bottom: 12px;
                left: 0;
                opacity: 0;
                transition: 0.5s;
            }
            &:hover {
                color: var(--color-header);
                &::before {
                    opacity: 1;
                    width: 100%;
                }
            }
            &.active {
                color: var(--color-header);
                &::before {
                    width: 100%;
                    opacity: 1;
                }
            }
        }
    }

    > .menu-item-has-children {
        position: relative;
        > a {
            position: relative;
            svg {
                margin-left: 2px;
                font-size: 14px;
            }
        }
        .axil-submenu {
            position: absolute;
            top: 100%;
            left: 20px;
            background: #ffffff;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            min-width: 250px;
            padding: 15px 10px;
            border-radius: 4px;
            transition: var(--transition);
            list-style: none;
            margin: 0;
            box-shadow: 0 13px 48px 0 rgba(0, 0, 0, 0.15);
            li {
                margin: 0;
                transition: var(--transition);
                opacity: 0;
                transform: translateX(20px);
                a {
                    position: relative;
                    font-size: 14px;
                    text-transform: capitalize;
                    color: var(--color-body);
                    font-weight: 500;
                    padding: 7px 15px;
                    border-radius: 4px;
                    display: block;
                    transition: var(--transition);
                    &::after {
                        content: '';
                        height: 1px;
                        width: 0;
                        background-color: var(--color-header);
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        transition: var(--transition);
                    }
                    &:hover {
                        color: var(--color-header);
                        &::after {
                            width: 100%;
                        }
                    }
                    &.active {
                        color: var(--color-header);
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &:hover {
            .axil-submenu {
                opacity: 1;
                visibility: visible;
                z-index: 9;
                left: 0;
                li {
                    opacity: 1;
                    transform: translateX(0);
                    &:nth-child(1n) {
                        transition-delay: 0.1s;
                    }
                    &:nth-child(2n) {
                        transition-delay: 0.2s;
                    }
                    &:nth-child(3n) {
                        transition-delay: 0.3s;
                    }
                    &:nth-child(4n) {
                        transition-delay: 0.4s;
                    }
                    &:nth-child(5n) {
                        transition-delay: 0.5s;
                    }
                    &:nth-child(6n) {
                        transition-delay: 0.6s;
                    }
                    &:nth-child(7n) {
                        transition-delay: 0.7s;
                    }
                    &:nth-child(8n) {
                        transition-delay: 0.8s;
                    }
                    &:nth-child(9n) {
                        transition-delay: 0.8s;
                    }
                    &:nth-child(10n) {
                        transition-delay: 0.8s;
                    }
                    &:nth-child(11n) {
                        transition-delay: 0.8s;
                    }
                    &:nth-child(12n) {
                        transition-delay: 0.8s;
                    }
                }
            }
        }
    }
}
